/*
(function( $ ) {

  'use strict';

  // Map Markers
  var mapMarkers = [{
      address: "217 Summit Boulevard, Birmingham, AL 35243",
      html: "<strong>Alabama Office</strong><br>217 Summit Boulevard, Birmingham, AL 35243",
      icon: {
          image: "img/pin.png",
          iconsize: [26, 46],
          iconanchor: [12, 46]
      }
  },{
      address: "645 E. Shaw Avenue, Fresno, CA 93710",
      html: "<strong>California Office</strong><br>645 E. Shaw Avenue, Fresno, CA 93710",
      icon: {
          image: "img/pin.png",
          iconsize: [26, 46],
          iconanchor: [12, 46]
      }
  },{
      address: "New York, NY 10017",
      html: "<strong>New York Office</strong><br>New York, NY 10017",
      icon: {
          image: "img/pin.png",
          iconsize: [26, 46],
          iconanchor: [12, 46]
      }
  }];

  // Map Initial Location
  var initLatitude = 40.75198;
  var initLongitude = -73.96978;

  // Map Extended Settings
  var mapSettings = {
      controls: {
          panControl: true,
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: true,
          overviewMapControl: true
      },
      scrollwheel: false,
      markers: mapMarkers,
      latitude: initLatitude,
      longitude: initLongitude,
      zoom: 4
  };

  var map = $('#googlemaps').gMap(mapSettings);

  var mapRef = $('#googlemaps').data('gMap.reference');

  // Styles from https://snazzymaps.com/
  var styles = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];

  var styledMap = new google.maps.StyledMapType(styles, {
      name: 'Styled Map'
  });

  mapRef.mapTypes.set('map_style', styledMap);
  mapRef.setMapTypeId('map_style');

  // Redraw Map On Resize
  $(window).afterResize(function(){
      $('#googlemaps').gMap(mapSettings);
      var mapRef = $('#googlemaps').data('gMap.reference');

      mapRef.mapTypes.set('map_style', styledMap);
      mapRef.setMapTypeId('map_style');
  });

}).apply( this, [ jQuery ]);
*/