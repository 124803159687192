$(document).ready( function() {

  $('.owl-carousel.manual').owlCarousel({
    'responsive' : {
      0 : {'items' : 1},
      480 : {'items': 2},
      768 : {'items' : 3},
      991 : {'items' : 4}
    }
  });

  /*
  $('.owl-carousel.manual').each(function(){
    console.log($(this).data('plugin-options'));
    $($(this)).owlCarousel($(this).data('plugin-options'));
  });
  */
  
  /*
  $('.owl-carousel.owl--dots').owlCarousel({
    dots: true,
    nav: false,
    responsive:{
      0:{
          items:1
      },
      600:{
          items:2
      }
    }
  });
  $('.owl-carousel.owl--nav').owlCarousel({
    dots: false,
    nav: true,
    responsive:{
      0:{
          items:1
      },
      600:{
          items:2
      }
    }
  });
  */
});